.digitalidLabel {
  width: 30%;
  font-weight: 500;
  padding-left: 5px;
  padding-right: 5px;
  text-align: right;
  margin-bottom: 5px;
}

.digitalidItem {
  text-align: right;
  margin-left: 3px;
  margin-bottom: 5px;
}