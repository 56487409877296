#formEdit-title-input {
    width: 90%;
    border-radius: 3px;
    padding: 5px;
    color: black;
    background-color: white;
    border: 1px solid lightgrey;
 }
 
 #formEdit-title-input::placeholder {
    color: #ccc;
 }
