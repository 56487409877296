.enform-label {
   font-weight: 500;
   border-bottom: 1px solid lightgrey;
}

.enform-back-button {
   text-align: right;
   margin: 5px;
}

.form-title-bar {
   font-weight: bold;
   background-color: gold;
}
