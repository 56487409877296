ul.enform-breadcrumb {
   padding: 10px 16px;
   list-style: none;
   display: grid;
   grid-auto-flow: column;
   justify-content: start;
   margin: 0
}

ul.enform-breadcrumb li {
   display: grid;
   grid-auto-flow: column;
   align-items: center;
}

ul.enform-breadcrumb li+li:before {
   padding: 0px 8px;
   color: black;
   content: "/\00a0";
}

ul.enform-breadcrumb li a {
   color: #0275d8;
   text-decoration: none;
   display: grid;
}

ul.enform-breadcrumb li a:hover {
   color: #01447e;
   text-decoration: underline;
}