.error {
  color: red;
  font-size: .8rem;
  padding-left: 10px;
}

.formInput label {
  font-weight: bold;
}

.formInput {
  display: grid;
  gap: 5px;
}

.editorContainer {
  display: grid;
  gap: 15px;
  padding: 5px;
  border: solid 2px #999;
  border-radius: 4px;
}

.editorHeader {
  padding: 5px;
  background-color: #eee;
  font-weight: bold;
}

.actionButtons {
  display: grid;
  grid-auto-flow: column;
  gap: 4px;
  justify-content: right;
}