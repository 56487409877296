.item-container {
   display: flex;
   justify-content: space-between;
   width: 100%;
   padding: 3px 7px;
   border-bottom: lightgrey 1px solid;
}

.item-name {
   font-weight: bold;
}

.item-status {
   font-weight: bold;
   text-align: right;
   padding-left: 15px;
}

.item-title {
   font-style: italic;
   background-color: rgb(235, 235, 235);
   border-radius: 3px;
   padding: 1px 20px 1px 5px;
   width: 100%;
   text-align: left;
}
