.note-content {
   padding: 3px;
   border: 1px solid lightgrey;
}

.note-content-10 {
   font-size: 0.9rem;
}

.note-content-11 {
   font-size: calc(0.9rem * 1.1);
}

.note-content-12 {
   font-size: calc(0.9rem * 1.2);
}

.note-content-13 {
   font-size: calc(0.9rem * 1.3);
}

.note-content-14 {
   font-size: calc(0.9rem * 1.4);
}

.note-content-15 {
   font-size: calc(0.9rem * 1.5);
}

.note-content-16 {
   font-size: calc(0.9rem * 1.6);
}

.note-content-17 {
   font-size: calc(0.9rem * 1.7);
}

.note-content-18 {
   font-size: calc(0.9rem * 1.8);
}

.note-content-19 {
   font-size: calc(0.9rem * 1.9);
}

.note-content-20 {
   font-size: calc(0.9rem * 2);
}
