.setupContainer {
   display: grid;
   gap: 10px;
   padding: 5px;
}

.setupTitleBar {
   border: lightgrey solid 1px;
   background-color: lightgrey;
   padding: 5px;
   font-weight: bold;
}

.setupElement {
   padding: 10px;
}

.setupElementText {
   padding-top: 3px;
}

.setupStateDump {
   width: 100%;
}

.setupElementContainer {
   border: solid 1px #ccc;
   border-radius: 4px;
}