.container {
  display: grid;
  gap: 10px;
}

.row {
  /* background-color: #eee; */
  border-radius: 3px;
  padding: 10px;
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-content: start;
  gap: 10px;
  cursor: pointer;
  border-bottom: solid 1px #eee;
}

.icon {
  font-size: 24px;
}

.header {
  font-weight: bold;
  border-bottom: solid 1px #999;
  justify-self: center;
}

.link {
  text-decoration: underline;
  cursor: pointer;
}

.link:hover {
  color: blue;
}

.warning {
  padding: 5px;
  margin: 10px;
  border-radius: 2px;
  text-align: center;
  background-color: #f2f2f2;
}