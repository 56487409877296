.group-list-item {
   padding-top: 10px;
   padding-right: 10px;
   padding-left: 10px;
   padding-bottom: 5px;
   border-bottom: lightgrey solid 1px;
}

.group-list-top-right {
   width: 10%;
   text-align: right;
}
