.location-label {
   font-weight: 500;
   text-align: right;
   margin-right: 5px;
   width: 40%;
}

.location-input {
   width: 80%;
   border-radius: 2px;
   padding: 5px;
   height: calc(1.4285714286em + 10px);
   border-color: rgba(0, 0, 0, 0.08);
}
