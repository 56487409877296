.formnew-container {
   padding-left: 15px;
}

.formnew-button {
   margin-top: 5px;
   text-align: center;
}

.formnew-element {
   margin-bottom: 5px;
   border: 1px solid lightgrey;
}

.formnew-element > input::placeholder {
   color: #9b9b9b;
   opacity: 1;
}

.formnew-back-button {
   text-align: right;
   margin: 5px;
}

#formnew-title-input {
   width: 90%;
   border-radius: 3px;
   padding: 5px;
   background-color: white;
   border: 1px solid lightgrey;
}

#formnew-title-input::placeholder {
   color: #9b9b9b;
   opacity: 1;
}
