.setup-title-bar {
   border: lightgrey solid 1px;
   border-radius: 3px;
   background-color: lightgrey;
   padding: 3px;
   font-weight: bold;
}

.setup-button-group {
   display: flex;
   justify-content: space-between;
   margin-right: 5px;
}
