.safety-container {
   border: 1px solid red;
   border-radius: 5px;
   margin: 5px;
}

.safety-header {
   color: white;
   background-color: red;
   font-weight: 600;
   width: 100%;
   padding: 5px;
   text-align: center;
   display: flex;
   justify-content: space-between;
}

.safety-body {
   color: red;
   font-weight: 600;
   width: 100%;
   padding: 15px;
   text-align: center;
}
