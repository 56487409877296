.cardContainer {
  border-bottom: solid 2px #eee;
  padding: 5px;
  display: grid;
  grid-template-columns: auto min-content;
  align-items: center;
}

.cardDetail {
  display: grid;
  grid-auto-flow: row;
  justify-content: space-between;
}

.actionButtons {
  display: grid;
  grid-auto-flow: column;
  gap: 10px;
}

.bold {
  font-weight: bold;
}