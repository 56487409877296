.info-div {
   margin-top: 3px;
   border-bottom: 1px solid lightgrey;
}

.status-label {
   display: block;
   font-weight: 600;
   margin-top: 3px;
}
