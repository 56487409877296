.sync-button {
   margin-top: 10px;
}

.sync-toast {
   margin-top: 10px;
   color: white;
   font-weight: bold;
}

.card-header {
   font-weight: bold;
}

.card-body {
   padding-left: 5px;
}

.sync-button-container {
   text-align: center;
}

.sync-loader-container {
   text-align: center;
}

.sync-offline-message {
   text-align: center;
   margin-top: 25px;
}
