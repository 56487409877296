.submitInfoItem {
   font-weight: 600;
   padding: 5px;
   background-color: #f2f2f2;
   border-bottom: 1px solid lightgrey;
}

.container {
   display: grid;
   gap: 10px;
   padding: 5px;
}

.sectionContainer {
   border: solid 1px #ccc;
   border-radius: 4px;
}

.sectionTitleBar {
   border: lightgrey solid 1px;
   background-color: lightgrey;
   padding: 5px;
   font-weight: bold;
}

.sectionSubTitle {
   display: grid;
}

.sectionContent {
   display: grid;
   padding: 10px;
   gap: 10px;
}

.actionBar {
   justify-self: right;
   margin-top: 3px;
}