.navigation-wrapper {
   margin: 0 auto;
   border-radius: 5px;
}

.navigation-page {
   padding: 3px 3px 0 3px;
}

.navigation-content {
   border-radius: 10px 10px 0 0;
   margin-top: 5px;
   padding-top: 33px;
   padding-bottom: 55px;
}

.appbar-title {
   font-size: large;
   font-weight: bold;
   margin: 0 0 0 0;
}

.navigation-connector-online {
   color: green;
}

.navigation-connector-offline {
   color: red;
}

@media only screen and (min-width: 60rem) {
   .navigation-wrapper {
      width: 60rem;
   }
   .navigation-footer {
      width: 60rem;
   }
   .navigation-header {
      width: 60rem;
   }
}
